.showcaseabout{
    z-index:3;
    background:var(--dark-color);
    padding:100px 150px;
    margin:200px auto;
    width:70%;
    max-width:950px;
  border-radius: 70px;

    @media screen and (max-width:800px) {
    padding: 30px 70px;  
    }
    @media screen and (max-width:400px) {
    width: 100%;
    margin:70px auto;
      padding: 100px 5px;
      background: none;
    }
  }
  .gapper{
    padding-top:200px;
    @media screen and (max-width:800px) {
     padding-top: 50px; 
    }
  }
  .aboutcontainer{
    width:60%;
    z-index:2;
    min-width:100px;
    
  }
  .brownieabout{
    position:absolute;
    width:90%;
    background:#80470E;
    z-index:-1;
    height:900px;
    @media screen and (max-width:800px) {
      height: 700px;
    }
    @media screen and (max-width:500px) {
      width: 100%;
        padding: 100px 5px;
        background: none;
      }
  }
  .orangieo{
    position:absolute;
    width:110%;
    background:#DE5A24;
    z-index:-2;
    height:500px;
    filter: opacity(0);
    visibility: hidden;

    @media screen and (max-width:800px){
        visibility: visible;
        filter: opacity(1);
    }
  }
  .abouthead{
    border-radius: 30px;
    background:var(--sub-color);
    width:110%;
    // height:400px;
    padding:15%;
    h1{
      color:var(--black-color);
      font-size:2vw;
      margin: 0;
    }
    p{
      color:black;
      font-size:20px;
    }
    @media screen and (max-width:800px) {
    //   height: 200px;
      width: 140%;
      transform: translateY(90px);  
      p{
        font-size:18px;
      }
      }
      @media screen and (max-width:400px) {
        // height: 170px;
        width: 180%;
        padding: 30px;
        transform: translateY(90px) translateX(30px);  
        h1{
          font-size: 20px;
        }
        p{
          font-size:18px;
        }
        }
  }
  .aboutimage{
    width:100%;
    z-index:0;
    overflow:hidden;
    min-width:300px;
  border-radius: 50px;

    z-index:1;
    @media screen and (max-width:500px) {
      transform: translateX(-40px); 
    }
    @media screen and (max-width:400px) {
      transform: translateX(-60px); 
    }
    img:hover{
      cursor: pointer;

    }
  }
.opened{
    @media screen and (max-width:400px){
        margin-left: 20px;
    }
}
  .opened .abouthead{
    transform: translate(0px);
    width: 100%;
    margin-top: 15vh;
    @media screen and (max-width:1300px){
        margin-top: 0vh;  
      }
      @media screen and (max-width:800px){
        padding-left: 40px;          
      }
      @media screen and (max-width:400px){
        margin-left: 20px;   
        p{
            font-size: 12px;       
        
        }
    }
  }

  .opened .aboutimage{
      transform: translateX(0px);
      padding-left: 200px;
      @media screen and (max-width:1300px){
        padding-left: 100px;          
      }
      @media screen and (max-width:800px){
        padding-left: 40px;          
      }
  }
  .overlay.about{
      background-color: black;
      filter: opacity(0.8);
  }
  .opened .aboutimage,
  .opened .abouthead{
      z-index: 5;
  }


  .overlay.about .showcaseabout{
      filter: opacity(0.6) !important;
      width: 100% !important;
  position: fixed;
  padding:0 !important;
  margin:0 !important;
    max-width: 6000px !important;
    border-radius: 0px;

  }

  .overlay.about .showcaseabout{
    height: 40vh;
    z-index: 3;
    // display: none;
}
.splitmid{ 
  display:grid;
  top: 0;
  left: 0;
  z-index: 6;
  position: fixed;
  vertical-align: middle;
  padding: 40px 0;
  grid-template-columns: repeat(2, 1fr);
  @media  (max-width:600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (min-width:1500px){
      margin-top:200px;
  }
}
