.aboutcontain{
    // width:100%;
    padding:5vw;
    font-family: Bilo, "Bilo";
    background-color: var(--dark-color);
    @media (max-width:550px) {
      padding: 20px;
    }
  }
  .aboutinner{
    padding-bottom: 40px;
    img{
        width: 50px;
        height: 50px;
        @media (max-width:550px) {
          width: 30px;
        height: 30px;
        }
    }
  }
  .aboutHead{
    padding-top: 1rem;
    font-weight: bold;
    font-size:20px;
  }
  .authorName{
          // vertical-align:top;
          font-weight: bold;
          font-size:20px;
          // padding: 20px 20px;
          font-family: Bilo, "Bilo";
          @media (max-width:550px) {
            // font-size:18px;
      }
  }

.adesc{
  vertical-align:top;
  font-family: Bilo, "Bilo";
  font-weight: normal;
  font-size:21px;
  padding: 20px 0px;
  margin-top: 40px;
  padding-bottom: 0px;
  @media (max-width:550px) {
    // font-size:10px;
  }
}
  

