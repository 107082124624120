.contain{
    position: relative;
    padding: 40px 0px;
    margin: 0;
}

.textcontain{
    font-weight: 200;
    padding:30px 40px;

    @media (min-width:800px){
        padding: 60px 80px !important;
    }
    @media (min-width:1200px){
        .pubdate{
            padding-bottom: 20px;
        }
    }
    @media (max-width:600px){
        padding: 60px 10px !important;
    }
}
.blogtitle{
    font-size: 50px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.pubdate{
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
}
.author{
    font-weight: 400;
    font-size: 20px;
    margin: 0;
}

.bodybody{
    font-family: "pragmatica";
    border-radius: 15px;
    background-color: rgb(242, 235, 228);
    padding: 10px 20px;
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    line-height:34px;
    margin-top: 20px;

    @media (max-width:800px) {
        font-size: 20px;
    }        
    @media (max-width:730px) {
        font-size: 15px;
       line-height:25px;
    }   

    h2{
        font-weight: 600;
        line-height:34px;
        font-size: 40px;
        @media (max-width:730px) {
            font-size: 23px;
            line-height:40px;
        }  
    }
    h3{
        font-weight: 600;
        font-size: 30px;
        @media (max-width:730px) {
            font-size: 23px;
           line-height:40px;
        } 
    }

    p{
        font-weight: 400;
        font-size: 20px;
        text-align: justify;
        line-height:34px;
        @media (max-width:800px) {
            font-size: 20px;
        }        
        @media (max-width:730px) {
            font-weight: normal;
            font-size: 15px;
            line-height: 25px;
        }



    }
    em{
        font-weight: 600;
    }
    li{
        font-weight: 500;
        font-size: 20px;
    }
    strong{
        font-weight: bolder;
    }
    a{
        color: #de5a24;
        font-weight: normal;
    }
}