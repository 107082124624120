.seecontain{
  padding: 5vw;
  border-radius: 40px;
    background-color: var(--sub-color);
    @media screen and (max-width:800px){
      padding: 2vw;
    }
  }
  .seeinner{
    padding-bottom: 2rem;
  }
  .seeHead{
    font-weight: bold;
    font-size:25px;
    margin: 0;
    padding-bottom: 2vh;
  }


  .seeauthor{
     display: flex;
     justify-content: space-around;
     align-items:center;
     text-align: center;
     font-family: Bilo, "Bilo";
     div{
       vertical-align: top;
       padding: 20px 0px;
       a{
         text-decoration: none;
          color: black;
          padding-top: 2vh;
          font-size: 18px;
       }
       p{
         font-weight: 400;
         
       }
       padding-bottom: 3vh;
      @media screen and (max-width:400px) {
        img{
          width: 100px;
          padding-bottom: 70px;
        }
        p{
          font-size: 17px;
          margin: 0px;
          margin-top: 20px;
        }
      }
     }
     @media screen and (max-width:650px) {
      display: table;
      column-count: 1;
    } 
    
  }