
.actions {
    z-index: 4;
    display: block;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    padding: 0.5rem;
    background-color: var(--main-color);
    border-radius: 1rem;
  }
  
  .actions > button {
    padding: 0.2rem 1rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    border: 0;
    font-size: 1rem;
    transition: all 0.1s ease-in-out;
    color: var(--secondary-color);
    background-color: var(--sub-color);
  }
  
  .actions > button:hover,
  .actions > button:focus {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .actions > button:last-child {
    margin-right: 0;
  }
  