$main: #f0d8bb;
$white: #ffffff;
$black: #1e1f13;

:root{
  --main-color:#DE5A24;
  --bg-color:#f0d8bb;
  --sub-color:rgb(250, 238, 229);
  --dark-color:#c9b9ac;
  --secondary-color:#80470E;
  --black-color:#1e1f13;
  --blog-card-color:#f0d8bb;
}


* {
  // @media screen and (min-width:800px){
  // font-family: "HelveticaNeue-CondensedBold", "Open Sans", "Helvetica Neue",
  //   sans-serif,Bilo,"Bilo";
  // }
      -webkit-text-size-adjust: none;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility; 
      
}
h1{
  font-family: Bilo,"Bilo";
}
h2,span{
  font-family: "Open Sans";
}
::-webkit-scrollbar {
  width: 9px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--sub-color);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 10px;
}

.primarytext{
  color: var(--main-color);
}

.secondarytext{
  color: var(--secondary-color);
}

.splide__arrow{
  svg{
    fill: var(--main-color) !important;
  }:hover{
    fill: var(--sub-color) !important;
  }
}

.splide__pagination__page{
  &.is-active {
    background:var(--main-color) !important;
  }
}
body {
  z-index: 1;
  padding: 0;
  margin: 0;
  background-color: $main;
  color: $black;
  &:before {
    animation: grain 8s steps(10) infinite;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png");
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.3;
    position: fixed;
    top: -100%;
    width: 300%;
  }
  &.no-scroll {
    overflow-y: hidden;
  }
}

.container {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;
  &.fluid {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  @media (min-width: 704px) {
    max-width: 800px;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1560px;
  }
  @media (min-width: 1708px) {
    max-width: 1960px;
  }

  .row {
    display: flex;
    align-items: center;
    &.space-between {
      justify-content: space-between;
    }
    &.center {
      justify-content: center;
    }
  }
}
@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}



