.backback{
    position: fixed;
    z-index: -2;
}
.detailth{
    display: flex;
    align-items: center;
    justify-content: space-around;
    vertical-align: middle;
    padding-top: 30vh;
}
.wet {
    @media (min-width: 1440px) {
      }
    .first {
      color: #80470E;
    }
    .middle{
      margin: 0 15px;
    }
    .last {
      color: #DE5A24;
    }
    span {
      display: inline-block;
      position: relative;
      font-weight: 400;
      font-size: 52px;
      @media (min-width: 460px) {
        font-size: 50px !important;
      }
      @media (min-width: 760px) {
        font-size: 90px !important;
      }
      @media (min-width: 1440px) {
        font-size: 154px !important;
      }
      @media (min-width: 1640px) {
        font-size: 224px !important;
      }
    }
  }