.yujititle{
    font-size: 35px;
    text-align: center;
}
.yujiform{
    text-align: center;
    h2{
        font-size: 17px;
        font-weight: 600;
        color: var(--secondary-color);
        padding-top: 15px;
    }
    .yujisubmit{
        margin-top: 50px;
        background-color: var(--bg-color);
        border-radius: 15px;
        padding: 10px 15px;
        border-color: var(--main-color);
        border-style: solid;
        border-width: 3px;
    }
    input{
        padding: 10px;
        border-color: var(--sub-color);
        border-radius: 10px;
        border-style: none;
        width: 250px;
    }
}