
.container {
  max-width: 90%;
  flex: 1 1 100%;
  padding: 45px 25px;
}



.date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}

header {
  border-bottom: 1px solid var(--divider);
  position: relative;
}

.avatar {
  background: var(--divider);
  border-radius: 50%;
  position: absolute;
  bottom: 12px;
  right: 0;
  overflow: hidden;
}

.avatar,
.avatar img {
  width: 40px;
  height: 40px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list {

  // background:var(--sub-color);
  padding:30px 100px;
  padding-bottom: 100px;
  @media  (min-width:1000px) {
    margin:20px 50px;
  }
  @media  (max-width:800px) 
  {
  padding: 30px 30px;  
  }
}

.card {
  position: relative;
  padding: 20px 10px;
  height: 460px;
  max-width: 100%;
  margin: 80px 0px;
  
}

// .card:nth-child(4n + 1),
// .card:nth-child(4n + 4) {
//   grid-column: 1 / span 6;
// }

// .pubDate{
//   text-align:center;
// }

// .card:nth-child(odd) {
//   padding-right: 0;
//   border-right: $border;

// }

// .card:nth-child(even) {
//   padding-left: 0;
//   padding-top: 30px;
//   transform: translateY(150px);
// }
// .card:nth-child(1) {
//   grid-column: 2 / span 5;
// }
// .card:nth-child(2) {
//   grid-column: 7 / span 6;
// }
// .card:nth-child(3) {
//   grid-column: 1 / span 6;
// }
// .card:nth-child(4) {
//   grid-column: 7 / span 5;
// }
// .card:nth-child(5) {
//   grid-column: 3 / span 4;
// }
// .card:nth-child(6) {
//   grid-column: 7 / span ;
// }
// .card:nth-child(7) {
//   grid-column: 2 / span 5;
// }
// .card:nth-child(8) {
//   grid-column: 7 / span 6;
// }
// .card:nth-child(9) {
//   grid-column: 1 / span 6;
// }
// .card:nth-child(10) {
//   grid-column: 7 / span 5;
// }
// .card:nth-child(11) {
//   grid-column: 3 / span 4;
// }
// .card:nth-last-child(2) {
//   padding-bottom: 50px;
// }


.card-content-container {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  overflow-x: hidden;
  padding: 40px 0;
  position: fixed;
  max-height: 100%;
  /* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}
}

.card-content {
  pointer-events: auto;
  position: relative;
  // border-radius: 20px;
  // border-width: 10px;
  // border-style: double;
  border-color: rgb(250, 238, 229);
  background:  var(--blog-card-color);
  // overflow: hidden;
  width: 100%;
  height: 100%;
}

.open .card-content {
  // height: auto;
  margin: 0 auto;
  z-index: 2;
  max-width: 80%;
  position: relative;
  border-style: none !important;
  overflow-y: scroll;
  @media (max-width:550px) {
    max-width: 90%;
  }
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  display: flex;
  height: 520px;
  width: 100%;
  grid-area: imgH;  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  } 
}



.open .card-image-container,
.open .title-container {
  z-index: 2;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
  grid-area: dataH;  
  h2{
    margin-bottom: 5px;
  }
}

.open .title-container {
  top: 30px;
  left: 30px;
}

.title-container>h2{
  color: white;
}
.card-image{
  filter: brightness(80%);
}
.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 5;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  @media (max-width:550px) {
    background: rgba(0, 0, 0, 0.4);
  }
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.content-container {
  padding: 30px 35px 35px 35px;
  max-width: 100%;
  @media (max-width:800px) {
    padding: 20px;
}
}

.underline
{
    font-family: bilo, sans-serif;
    font-style: normal;
    font-weight: 600;
    background-repeat: repeat-x;
    background-position: 0 1.15em;
    background-size: 10px 7px;
    @media (min-width:800px) {
        width: 55%;
    }
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
    top:30px;
  }
}

.footerBlog{
  padding: 5vh;
  padding-top: 2vh;
  .footerTitle{
    font-size: 40px;
    color: var(--sub-color);
    margin-bottom: 10px;
  }
  .theAuthor{
    background-color: var(--dark-color);
    .aboutcontain{
      padding: 20px 0px !important;
    }
  }
  margin: 20px 40px;
  border-radius :20px;
  background-color: var(--dark-color);
  .flex{
    display: flex;
    padding-top: 0vh;

  }
}

.subscribeBlog{
  
  .subscribeTitle{
    font-size: 40px;
    color: var(--secondary-color);
    margin-bottom: 10px;
  }
  .subscribeNote{
    font-size: 20px;
    font-weight: 500;
  }
  background-color: var(--sub-color);
  margin: 20px 40px;
  border-radius :20px;
  min-height: 300px;
  margin: 60px 40px;
  margin-bottom: 100px;
  padding: 5vw;
  input{
    border-style: hidden;
    padding: 10px 30px;
    width: 80%;
  }
  .tick{
    padding: 5px;
    width: 10%;

  }
  .subButton{
    margin: 30px 30px;
    border-radius: 40px;
    padding: 14px 30px;
    color: var(--black-color);
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 18px;
    border-width: 10px;
    max-width: 400px;
    border-style: solid;
    border-color: var(--main-color);
    background-color: var(--sub-color);
  @media screen and (min-width:1000px){
    margin-top: 70px;
  }
  }
}
.ipl-progress-indicator.position{
  position:static ;
}
.insp-logo-frame-img.position{
  margin-top: 0px;
  width: auto;
  height: auto;
}
.loading-message{
  text-align: center;
}