//Home

main {
  height: 80%;
    overflow: hidden;
    .container {
      position: relative;
      .row {
        height: 80vh;
        align-items: center;
        .image-container {
          position: relative;
          .thumbnail {
            overflow: hidden;
            margin-bottom: -40px;
            margin-top: -100px;
            .frame {
              img {
                width: 100%;
              }
            }
          }
          .information {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 10px;
            text-transform: uppercase;
            .location {
              span:nth-child(2) {
                margin-left: 8px;
              }

            }
          }
        }
      }
    }
  }
  @media (min-width: 765px) {
    .information {
        font-size: 20px !important;
    }
    .thumbnail {
        margin-bottom: -80px !important;
    }
  }
  @media (min-width: 1240px) {
    .thumbnail {
        margin-bottom: -90px !important;
        margin-top: -140px !important;
        }
  }
  @media (min-width: 1407px) {
    .thumbnail {
        margin-bottom: -110px !important;
        }
  }

  .tapabove{
    text-decoration: none;
    color: black;
    text-align: center;
    margin-top: 20px;
    @media  (min-width:700px) {
      display: none;
    }
  }