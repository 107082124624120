@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;600;800&display=swap");
$load: #c05b08;
$notload:  #f8f2e9;
$backdropload: rgb(235, 220, 201);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{height:100%;margin:0;padding:0;width:100%}
body{font-family:Roboto,sans-serif;font-size:13px;-webkit-font-smoothing:antialiased}
.ipl-progress-indicator.available{opacity:0}
.ipl-progress-indicator{background-color:$backdropload;width:100%;height:100%;position:fixed;opacity:1;pointer-events:none;-webkit-transition:opacity cubic-bezier(.4,0,.2,1) 436ms;-moz-transition:opacity cubic-bezier(.4,0,.2,1) 436ms;transition:opacity cubic-bezier(.4,0,.2,1) 436ms;z-index:9999}
.insp-logo-frame{display:-webkit-flex;display:-moz-flex;display:flex;-webkit-flex-direction:column;-moz-flex-direction:column;flex-direction:column;-webkit-justify-content:center;-moz-justify-content:center;justify-content:center;-webkit-animation:fadein 436ms;-moz-animation:fadein 436ms;animation:fadein 436ms;height:98%}
.insp-logo-frame-img{
  margin-top:400px;
  width:612px;
  height:612px;
  -webkit-align-self:center;
  -moz-align-self:center;
  align-self:center;
  border-radius:50%;

  @media screen and (max-width:800px){
    width:212px;height:212px;
    margin-top:150px;
  }
}
.ipl-progress-indicator-head{background-color:$notload;height:4px;overflow:hidden;position:relative}
.ipl-progress-indicator .first-indicator,.ipl-progress-indicator .second-indicator{background-color:$load;bottom:0;left:0;right:0;top:0;position:absolute;-webkit-transform-origin:left center;-moz-transform-origin:left center;transform-origin:left center;-webkit-transform:scaleX(0);-moz-transform:scaleX(0);transform:scaleX(0)}
.ipl-progress-indicator .first-indicator{-webkit-animation:first-indicator 2s linear infinite;-moz-animation:first-indicator 2s linear infinite;animation:first-indicator 2s linear infinite}
.ipl-progress-indicator .second-indicator{-webkit-animation:second-indicator 2s linear infinite;-moz-animation:second-indicator 2s linear infinite;animation:second-indicator 2s linear infinite}
.ipl-progress-indicator .insp-logo{animation:App-logo-spin infinite 20s linear;border-radius:50%;-webkit-align-self:center;-moz-align-self:center;align-self:center}
@keyframes App-logo-spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}
@-webkit-keyframes fadein{from{opacity:0}to{opacity:1}}
@-moz-keyframes fadein{from{opacity:0}to{opacity:1}}
@keyframes fadein{from{opacity:0}to{opacity:1}}
@keyframes first-indicator{0%{transform:translate(0) scaleX(0)}25%{transform:translate(0) scaleX(.5)}50%{transform:translate(25%) scaleX(.75)}75%{transform:translate(100%) scaleX(0)}100%{transform:translate(100%) scaleX(0)}}
@keyframes second-indicator{0%{transform:translate(0) scaleX(0)}60%{transform:translate(0) scaleX(0)}80%{transform:translate(0) scaleX(.6)}100%{transform:translate(100%) scaleX(.1)}}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
