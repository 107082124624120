$primary: "#80470e";
.foot{
    position: relative;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    padding: 60px 0px;
    @media screen and (min-width:700px) {
    }
    @media screen and (min-width:1400px) {
    }
    @media screen and (min-width:1700px) {
        height: 350px;
        padding: 150px 20px;
    }
  }
.innerfoot{
    background-color: #c9b9ac;
    border-top: "1px solid #E7E7E7";
    text-align: "center";
    width: "100%";
    text-align: center;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    justify-content: center;
}
.logofoot{
grid-column: span 3/span 3;
img{
    width: 150px;
    @media screen and (min-width:500px) {
        width: 200px;
    }
    @media screen and (min-width:700px) {
        width: 270px;
    }
    @media screen and (min-width:1400px) {
        width: 350px;
    }
    @media screen and (min-width:1700px) {
        width: 550px;
    }
}
}
.links{
    grid-column-start: 4;
    grid-column: span 3/span 3;
    color: '#80470e';
}
.firstlog{
    padding-left: 40px;
}
.middlelog{
padding:  0px 1.25rem;
}