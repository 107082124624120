$orange:#DE5A24;
.sailormickle{
    margin-left: 20px;
    padding-bottom: 20px;
}
.bf{
  float: left;
width: 50%;
}

.bigblogheader{
  color: var(--sub-color);
  font-size: 3vw;
  margin: 20px 0px;
  @media screen and (max-width:1250px) {
    color: var(--black-color);
  }
  @media screen and (max-width:550px) {
    font-size: 8vw;

  }
}
.bigblogheader2nd{
  color: var(--black-color);
  text-align: center;
  font-size: 2vw;
  margin: 20px 0px;
  padding-top:250px;
  padding-bottom: 150px;
  font-weight: 400;
  // text-decoration: ;

  @media screen and (max-width:1250px) {
    padding-top:70px;
    padding-bottom: 70px;

  }
  @media screen and (max-width:550px) {
    font-size: 8vw;

  }
}
.miniHContainer{
  margin-top: 150px;
  text-align: center;
  padding:20px 0px;
  // opacity: 0.2;
  background-color: var(--dark-color);
  a{
    opacity: 1 !important;
    margin-top:60px;  
    vertical-align: text-top;
    font-weight: 800;
  }
}
.blogposter{
  border-radius: 70px;
  background:var(--sub-color);
   margin:30px 150px;
   padding: 30px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-gap: 1em;
  >h1{
    grid-column: 1 / span 12;
    text-align: center;
    font-size: 3vw;
    @media screen and (max-width:550px) {
      font-size: 8vw;
  
    }
  }
  span{
    color: var(--main-color);
  }
  >img{
    grid-column: 2 / span 10;
  }
  >p{
    grid-column: 2 / span 10;
  }
  >div{
    grid-column: 2 / span 10;

  }
  @media screen and (min-width:2000px){
    margin-top: 200px;
    
  }
  @media screen and (max-width:1450px) {
    padding:30px 0px;
    padding-bottom: 100px;
    margin:0;
  }
  @media screen and (max-width:550px) {
    padding:30px 0px;
    margin:0;
  }
}
.photographysquare{
  display: grid;
  grid-column: 1 / span 2;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 3em;
  overflow: hidden;
  @media screen and (max-width:800px){
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1em;
  }
  @media screen and (max-width:400px){
    grid-template-columns: repeat(1,1fr);
  }
}
.psquare{
  // height:300px;
  cursor: pointer;
  border-radius: 30px;
  overflow:hidden;
  @media screen and (max-width:800px){
    // height:200px;
  }
  @media screen and (max-width:400px){
    // height:200px;
  }
}
.blogmaintitleH{
  top: 0;
  text-align:center;
  font-size:6vh;
  grid-column: 1 / span 12;
  vertical-align: text-top;
}

.miniH{
  font-size: 6vh;
  text-align: center;
  font-weight: 400;
}

.belowhero{
    font-size: 7vw;
    width: 100%;
    font-weight: 900;
    @media (max-width:770px){
        padding-top: 30px;
        font-size: 70px;
    }
    @media (max-width:570px){
        padding-top: 30px;
        font-size: 40px;
    }
    @media (max-width:370px){
        padding-top: 30px;
        font-size: 40px;
    }
    a{
      color: inherit;
      text-decoration: none;
    }
    .selected{
      color: $orange;
    }
}
.blogtop{
    padding: 0px 0px;
    // pointer-events: none;
  margin-top: 20vw;
  margin-bottom:3vw;

}
.location{
    font-weight: 900;
    @media screen and (min-width:1200px){
      font-weight: 700;
      
    }
}
.insta{
    font-weight: 900;
    @media screen and (min-width:1200px){
      font-weight: 700;
      
    }
}
.comingsoon{
    padding: 100px 0px;
    
    font-weight: 300;
    @media (min-width:1700px){
        font-size: 54px;
    }
    @media (max-width:770px){
        padding: 30px 10px;
    }
    @media (max-width:370px){
        font-size: 24px;
    }
}
#share{
    width: 100%;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
}
#left{
    float: left;
    width: 50%;
    @media screen and (max-width:768px){
        float: none;
        width: 100%;
    }
}
#right{
    float: right;
    width: 50%;
    @media screen and (max-width:768px){
        float: none;
        width: 100%;
    }
}

.calltome{
    color: black;
    border-color: #DE5A24;
    background-color: transparent;  
    font-size: 15px;

    margin: 30px 0px;
    margin-left:30px;   
    padding: 5px 40px;
    border-radius: 150px;
    border-width: 6px;
  
  }
  .blogmaintitle{
      font-size: 3vh;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .author{
      font-size: 20px;
      font-weight: 300px;
  }
  .pubdate{
    font-size: 20px;
    font-weight: 300px;
  }
  .middlise{
    display:flex;
    justify-content: space-around;
    align-items:center;
  }
.two{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 1em;
}
.blogpost{
    margin: 0px;
    border: 2px ;
    border-top-left-radius: 50px 50px;
    background-color: var(--bg-color);
    margin-top: 60px;
    @media (min-width:1000px){
        border-top-left-radius: 100px 100px;
    }
    @media (max-width:800px) {
        font-weight: normal;
    }
    a{
      display: grid;
      grid-template-areas: 
      'imgH imgH dataH dataH dataH';
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-gap: 1em;
      @media (max-width:800px) {
        grid-template-areas: 
      'imgH' 
      'dataH';
    }
    }

    img{
        border: 2px ;
        border-top-left-radius: 50px 50px;
        width:100%;
        @media (min-width:1000px){
            border-top-left-radius: 100px 100px;
        }
    }
    @media screen and (max-width:550px) {
      margin-top: 20px;
    }
}
.dataH{
  padding: 20px 40px;
  grid-area: dataH;  
  @media screen and (max-width:550px) {
    padding: 0px;
  }
}
.imgH{
  max-width: 600px;
  width: 100%;
  grid-area: imgH;  

}
.highlighted{
    width:inherit;
    @media (max-width:1000px){
        width:80%;
    }
}
.underline
{
    font-family: bilo, sans-serif;
    font-style: normal;
    font-weight: 600;
    background-repeat: repeat-x;
    background-position: 0 1.15em;
    background-size: 10px 7px;
    @media (min-width:800px) {
        width: 55%;
    }
}
@media (prefers-reduced-motion: no-preference) {
    .spin {
      animation: App-logo-spin infinite 2s linear;
    }
    .reappear{
        animation: Flick infinite s;
    }
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes Flick {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $orange;
    color: $orange;
    box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    animation: dotTyping 1.5s infinite linear;
  }
  
  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    }
    50% {
      box-shadow: 9984px 0 0 0 $orange, 9999px -10px 0 0 $orange, 10014px 0 0 0 $orange;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px -10px 0 0 $orange;
    }
    100% {
      box-shadow: 9984px 0 0 0 $orange, 9999px 0 0 0 $orange, 10014px 0 0 0 $orange;
    }
  }
  
  .model{
    @media (min-width:1600px){
      margin-top: 150px;
    }
  }

  /* The container */
.containerb {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerb:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerb input:checked ~ .checkmark {
  background-color: #DE5A24;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerb .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.goalcol{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  @media  (max-width:600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.quotehome{
  font-weight: 400;
  padding-bottom: 10vh;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
  font-style: italic;
  height: auto;
  margin-top: 250px;
  font-family: "Bilo";
  font-size:42px !important;
  line-height: 60px;

    @media screen and (max-width:1400px){
      font-size: 50px !important;
      line-height: 60px;
    margin-top: 200px;
    }
    @media screen and (max-width:1000px){
      font-size: 30px !important;
      line-height: 60px;
      margin-top: 100px;
    }
    @media screen and (max-width:768px){
      font-size:22px !important;
      margin-top: 200px;
  
      }

  font-size: 4vh;
}

.outline {
  // position: absolute;
  border: 4px solid var(--secondary-color);
  border-radius: 20px;
}
.highlighted.moment{
  margin-bottom: 60px;
}
.psquare.selected{
  filter: grayscale(0.9);
}
.gah{
  background-color: var(--sub-color);
  border-bottom: 10px solid var(--main-color);
  // border-top: 10px solid var(--main-color);
  border-radius: 50px;
  
}
.gah.open{
  text-align: center;
  background-color: var(--sub-color);
  padding: 5vh 5vw;
  // padding-bottom: 12vw;
  @media screen and (min-width:1200px){
    margin: 0 100px;
  }
  @media screen and (min-width:1700px){
    margin: 0 200px;
  }
  margin-bottom: 200px !important;

  .frame{
    width: 100%;
    margin: 0vh auto;

  }
  img{
    margin: 0px auto;
    border-radius: 30px;
    border-top: 10px solid var(--secondary-color);
  border-bottom: 10px solid var(--secondary-color);

  }
  .body{
    padding: 50px 0px;
    text-align: left;
    > *{
      margin: 0px;
    }
  }
  .body > .title{
    font-size: 30px;

  }
  .body > .time{
    font-size: 12px;
  }
  .body > .content{
    margin-top: 20px;
    font-size: 16px;

  }
}
