$main: #f0d8bb;
$white: #ffffff;
$black: #1e1f13;
//Header

header {
  font-size: 16px;
  height: 20%;
  position: static;
  z-index: 1;
  width: 100%;
  .container {
    padding: 0 62px;
    .row {
      height: 80px;
      align-items: center;
      .logo {
        a {
          color: $black;
          text-decoration: none;
          span{
            font-weight: 800;
          }
        img{
          width:150px;
        }
        }
      }
      .menu{
        cursor: pointer;
        a{
          text-decoration: none;
          color: #1e1f13;
        font-weight: 700;

        }
        a:hover{
          color: #DE5A24;
        }
      }
    }
  }
}
@media (min-width: 765px) {

  .menu {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    a{
      font-weight: 900 !important;
    }
  } 
  .container{
    padding: 0 72px;
  }
  .row {
    height: 128px;
    padding-top: 20px;
  }
  .logoimg{
    width: 300px !important;
  }
}

@media (min-width: 1240px) {
  .row {
    height: 128px;
    padding-top: 40px;
  }
  .logoimg{
    width: 400px !important;
  }
}

@media (min-width: 1657px) {
  .container{
    padding: 0 62px;
  }
  .row {
    height: 128px;
    padding-top: 60px;
  }
  .logoimg{
    width: 500px !important;
  }
}
