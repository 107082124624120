$main: #f0d8bb;
$white: #ffffff;
$black: #1e1f13;
$brown: #80470E;
$orange: #DE5A24;
//single
.single {
  display: block;
  position: relative;
  margin-bottom: 10%;
    .container {
      .top-row {
        height: 35vh;
        width: 100%;
        align-items: flex-end;
        .top {
          padding-bottom: 20px;
          .detailss {
            display: flex;
            align-items: center;
            animation: scatter 1.75s infinite;
            // position: absolute;
            justify-content: space-around;
            margin: 15px -5vw;
            font-size: 5px;
            @media (min-width:370px){
              margin: 30px -10vw;
              font-size: 10px;
          }
          @media (min-width:700px){
            margin: -35px -10vw;
            font-size: 20px;
          } 
          @media (max-width:1000px){
          margin: -65px -10vw;
          font-size: 20px;
          }
          @media (max-width:1300px){
            margin: -50px -10vw !important;
            font-size: 20px;
          }
          @media (max-width:1440px){
            margin: -90px -10vw !important;
            font-size: 20px;
          }     
          @media (min-width:1440px){
                margin: 110px -17vw !important;
                font-size: 20px;
            }

            .location {
              font-size: 20px;
              span:nth-child(2) {
                margin-left: 16px;
              }
              @media (max-width:600px){
                font-size: 10px;
              }
            }
            .insta{
              font-size: 20px;
              @media (max-width:600px){
                font-size: 10px;
              }
            }
            .mua {
              text-transform: uppercase;
              
            }
          }
          .model {
            @media (min-width: 1440px) {
              }
            .first {
              color: #80470E;
            }
            .middle{
              margin: 0 15px;
            }
            .last {
              color: #DE5A24;
            }
            span {
              display: inline-block;
              position: relative;
              font-weight: 400;
              font-size: 52px;
              @media (min-width: 460px) {
                font-size: 50px !important;
              }
              @media (min-width: 760px) {
                font-size: 100px !important;
              }
              @media (min-width: 1000px) {
                font-size: 124px !important;
              }
              @media (min-width: 1000px) {
                font-size: 104px !important;
              }
              @media (min-width: 1440px) {
                font-size: 174px !important;
              }
              @media (min-width: 1640px) {
                font-size: 224px !important;
              }
            }
          }
        }
      }
      .bottom-row {
        height: 50vh;
        position: relative;
        @media screen and (max-width:800px){
          height: 25vh;
        }
        .bottom {
          height: 100%;
          width: 100%;
          .thumbnail-single {
            width: 100%;
            height: 800px;
            margin: 0 auto;
            overflow: hidden;
            position: absolute;
            padding-bottom: 42vh;
            left: 0;
            right: 0;
            z-index: 4;
            @media (min-width: 1000px) {
              padding-bottom: 80vh;
        
            }
            .frame-single {
              // display:grid;
              // grid-template-columns: repeat(1, 1fr);
              text-align: center;

              img {
                width: 100%;
              pointer-events: none;

              }
              .pull-me{
                padding: 0px;
              pointer-events: none;
              font-size: 4vw;

              }
            }
          }
        }
        .scroll-for-more {
          position: absolute;
          bottom: 200px;
          left: 10vw;
          z-index: 20;
          .icon {
            svg {
              height: auto;
              width: 28px;
            }
          }
          .text {
            margin-top: 8px;
            color: $white;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  
  .model >span >span{
    font-family: bilo, sans-serif !important;
    font-style: normal;
  font-weight: 200;
  }

  .detailed-information {
    height: 500px;
    @media (min-width:740){
      margin-top: 200px;
      height: 600px;
    }
    .container {
      .row{
        width:100%;
        align-items: flex;
        columns: 1;
        .title {
          span{
            display:block;
          font-size: 30px;  

          @media screen and (min-width:768px){
          font-size:70px !important;
          }
          @media screen and (min-width:1200px){
            font-size: 160px !important;
            padding:20px 40px;
            margin-top: 80px;
            &.backboner{
              margin-top:-100px;
            }
          }
          @media screen and (min-width:1768px){
            font-size: 160px !important;
            padding:0px 40px;
            &.backboner{
              margin-top:-130px;
            }
          }}
          
        }
        .body {
          p{
          font-family: "Javanese Text";
          color:#80470E;
          font-size: 16px;
          line-height: 28px;
          font-weight: 100;
          text-align: justify;
          @media screen and (min-width:768px){
            font-size:22px !important;
            }
            @media screen and (min-width:1400px){
              font-size: 35px !important;
              
            line-height: 50px;
            padding: 0px 40px;
            padding-top:250px;
            &.finalfix{
              padding-top:40px;
            }
            }
            @media screen and (min-width:1768px){
              font-size: 45px !important;
              
            padding-left: 20px;
            
            line-height: 80px;
            padding-right: 0px;
            padding-top:600px;
            }
          &.error{
            @media screen and (min-width:1400px){
              font-size: 35px !important;
              
          line-height: 40px;
            padding: 0px 40px;
            padding-left:300px;
            }
          }
          }
        }
      }
    }
  }
  @media screen and (max-width:768px){
    .breakabler {
        display: inline-block;
        text-align: center;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
        padding-left: 0%;
      -webkit-transform:  rotate(180deg);
      transform:  rotate(180deg);
    }
  
    .breakabler > .switch {
      display: block;
      margin: 0 auto 5px;
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
.underline
{
  color: #80470E;
    --color: #DE5A24;
    font-family: bilo, sans-serif;
    font-style: normal;
    font-weight: 600;
    background-image: linear-gradient(var(--color) 0%, var(--color) 100%);
    background-repeat: repeat-x;
    background-position: 0 1.15em;
    background-size: 10px 10px;
    
&.backboner{
  @media (min-width:1440px){
    background-position: 0 1.35em !important;
    background-size: 10px 10px;
    margin: 0 150px;
    margin-bottom: 100px;
  }
  @media (min-width:1740px){
    background-position: 0 1.25em !important;
    background-size: 20px 30px;
    margin: 0 150px;
  }
}}
.calltoaction{
  color: #80470E;
  border-color: #DE5A24;
  background-color: transparent;  
  font-size: 20px;
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 20px 100px;
  border-radius: 150px;
  @media (min-width:700px){
    margin-top: 140px;
    
  margin-left: 350px;
  }
  @media (min-width:1400px){
  font-size: 30px;
  margin-left: 50%;
  margin-top: 240px;
  margin-bottom: 100px;
  padding: 50px 200px;
  border-radius: 150px;
  border-width: 20px;
}
  @media (min-width:1700px){  
    margin-top: 340px;
    
  padding: 70px 350px;
  margin-bottom: 300px;
  }

}
.placeholder{
  padding:30px 20px;
  @media screen and (min-width:768px){
    padding:90px 30px;
    }
    @media screen and (min-width:1400px){
      padding:0px 120px;
      padding-top: 300px;
    }
    @media screen and (min-width:1700px){
      padding:0px 20px;
      padding-top: 500px;
    }
}
.quotehome{
  font-family: "Bilo";
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  text-align: left;
  font-style: italic;
  @media screen and (min-width:768px){
    font-size:22px !important;
    }
    @media screen and (min-width:1400px){
      font-size: 30px !important;
      line-height: 105px;
    }
    @media screen and (min-width:1700px){
      font-size: 70px !important;
      line-height: 105px;
    }
    .quoteauthor{
      text-align: right;
      float:right;
      
    font-weight: 400;
    }
    .emogenius{
      font-style: normal;
    }
}
#first{
  margin-top: -130px !important;
  @media (min-width:700px) {
    margin-top: 0px !important;
    padding-top: 50px;
  }
}
#backbone{
  padding-top: 60px;
  @media screen and (min-width:768px){
    padding-top: 80px;
    }
    @media screen and (min-width:1400px){
      padding-top: 0px;
      }

}

#backbone>.container>.breakabler {
  @media screen and (min-width:1400px){
    display: inline-block;
    text-align: center;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    padding-left: 0%;
  -webkit-transform:  rotate(180deg);
  transform:  rotate(180deg);
  }
}

#backbone>.container>.breakabler > .switch {
  @media screen and (min-width:1400px){
  display: block;
  margin: 0 auto 5px;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
}
#backbone>.container{
  height: 800px;
  @media screen and (min-width:1400px){
  padding-top: 350px;
  }
}
.logoicon{
  text-align: center;
  padding-top: 0px;
  @media screen and (min-width:500px) {
    padding-top:70px;
  }
  @media screen and (min-width:700px) {
    padding-top: 100px;
  }
  @media screen and (min-width:1400px) {
    padding-top: 20px;
  }
  @media screen and (min-width:1700px) {
    padding-top: 0px;
  }
  svg{
  width: 170px;
  @media screen and (min-width:500px) {
      width: 200px;
  }
  @media screen and (min-width:700px) {
      width: 270px;
  }
  @media screen and (min-width:1400px) {
      width: 550px;
  }
  @media screen and (min-width:1700px) {
      width: 750px;
  }
}
}
.hidden{
  
  display: none;
  
  @media screen and (min-width:1400px){
  display: block;
  }
  p{
      font-family: "Javanese Text";
    color:#80470E;
    font-size: 16px;
    line-height: 28px;
    font-weight: 100;
    text-align: justify;
    display: block;
    font-size: 35px !important;
    line-height: 50px;
      padding: 50px 200px;
      @media screen and (min-width:1700px){
        font-size: 46px !important;
        line-height: 70px;
        
      padding: 50px 10px;
      }
}
}

#offer{
  padding-top: 90px;
  @media screen and (min-width:408px){
    padding-top: 70px;
    }
  @media screen and (min-width:708px){
    padding-top: 200px;
    }
    @media screen and (min-width:1400px){
      padding-top: 1050px;
    }
    @media screen and (min-width:1700px){
      padding-top: 1250px;
    }
    @media screen and (min-width:1900px){
      padding-top: 1450px;
    }
}
.filler{
  width: 100%;
  min-height: 00px;
  @media screen and (max-width:350px){
    min-height:600px;
  }  
  @media screen and (max-width:400px){
    min-height:850px;
  }
  @media screen and (min-width:1400px){
    min-height:50vw;
    max-height: 200px;
  }
  @media screen and (min-width:1700px){
    min-height:54vw;
    max-height: 56vw;
  }

  @media screen and (min-width:2000px){
    min-height:43vw;
    max-height: 56vw;
  }
}
.test{
  color:#000000;
  font-size:"5px";
  font-weight:"bold";
  font-family:"'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif";
}
.pull-me{
  display: inline-block;
  
}
@keyframes scatter {
  0% {
    top: -20px;
  }
  50% {
    top: -30px;
  }
  100% {
    top: -20px;
  }
}